import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    beforeEnter() {
      location.href = 'https://www.osidex.stream'
    }
  },
  {
    path: '/:contentType/:videoId',
    name: 'ContentPlayer',
    component: () => import('../views/ContentPlayer')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
