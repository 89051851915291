import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-FTQKE0PMB7" },
  appName: 'OSIDEX Stream Player',
  pageTrackerScreenviewEnabled: true
}, router);

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  Vue,
  dsn: "https://29a6f836fa4941fab1004da090d558a8@o346960.ingest.sentry.io/5691658",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.1,
});


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
